/**
 * Copyright © 2023 - BVA Architects (Pty) Ltd. All rights reserved.
 *
 * @author Chanelle Bösiger <scheeperschanelle@gmail.com>
 */

// Imported React library and hooks.
import React from "react";
// Imported components from React Bootstrap.
import {Container, Row, Col, Image, Figure} from "react-bootstrap";

/**
 * Function to return an about us page that outlines what the company is about.
 * @returns {JSX.Element}
 * @constructor
 */
const AboutUs = () => {
    // Setting the URLs of the page's images.
    const mapImageSrc = "./images/practice/about/south_africa_work.webp";
    const leanneImageSrc = "./images/practice/staff/Leanne.webp";
    const wayneImageSrc = "./images/practice/staff/Wayne.webp";
    const rossImageSrc = "./images/practice/staff/Ross.webp";
    const ashenImageSrc = "./images/practice/staff/Ashen.webp";

    return (
        <Container className="w-100 pb-5">
            <header>
                <h3>About Us</h3>
            </header>
            <Row>
                <Col className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <Row>
                        <p>Welcome to BVA Architects, a leading architectural firm with a rich legacy that began in
                            1997. Over the years, our practice has flourished, earning a renowned reputation as a
                            respected architectural company. Specializing in the design of private healthcare
                            facilities in KZN and throughout South Africa, we have garnered extensive experience,
                            making us true leaders in our field. Our unwavering commitment revolves around delivering
                            unparalleled quality service to our clients. Our core principles centre on design
                            excellence and meticulous project construction supervision. We take immense pride in our
                            work and are driven by the desire to consistently exceed our clients' expectations.</p>
                        <h4>Our Talented Team:</h4>
                        <p>At the heart of our success lies our highly skilled and experienced team of colleagues and
                            professionals. Their passion for architecture, coupled with an unwavering enthusiasm,
                            drives them to explore new possibilities and innovate at every step. Together, we
                            collaborate with our clients to understand their unique vision and translate it into
                            reality.</p>
                        <Row>
                            <Col className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                <Figure>
                                    <Figure.Image className="practice_staff_img" src={wayneImageSrc}
                                                  alt="Wayne Allen"/>
                                    <Figure.Caption className="practice_staff_name">Wayne Allen<br/>
                                        <span className="practice_staff_role">- Director</span>
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                <Figure>
                                    <Figure.Image className="practice_staff_img" src={leanneImageSrc}
                                                  alt="Leanne Alexander"/>
                                    <Figure.Caption className="practice_staff_name">Leanne Alexander<br/>
                                        <span className="practice_staff_role">- Director</span>
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                <Figure>
                                    <Figure.Image className="practice_staff_img" src={ashenImageSrc}
                                                  alt="Ashen Budhoo"/>
                                    <Figure.Caption className="practice_staff_name">Ashen Budhoo<br/>
                                        <span className="practice_staff_role">- Associate</span>
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 text-center">
                                <Figure>
                                    <Figure.Image className="practice_staff_img" src={rossImageSrc}
                                                  alt="Ross Bösiger"/>
                                    <Figure.Caption className="practice_staff_name">Ross Bösiger<br/>
                                        <span className="practice_staff_role">- Associate</span>
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                        </Row>
                        <h4>Tailored Solutions:</h4>
                        <p>We recognize that each project is unique, with its own set of challenges and aspirations.
                            Our team works closely with clients, taking the time to listen and understand their
                            specific needs. This allows us to create tailored solutions that are reflective of our
                            clients' values and goals.</p>
                    </Row>
                </Col>
                <Col className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <Row>
                        <h4>Areas We Have Worked In:</h4>
                        <Image id="about_map" fluid src={mapImageSrc} alt="South Africa Map"/>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4>A Partnership Approach:</h4>
                    <p>We believe in forging strong partnerships with our clients. From the
                        initial stages of project inception to its successful completion and beyond, we remain
                        dedicated to offering proactive, ethical, and diligent services. Our collaborative approach
                        ensures that our clients' needs are always at the forefront of every decision we make.</p>
                    <p>In conclusion, we are not just creators of physical spaces; we are shapers of experiences,
                        inspirers of emotions, and partners in your journey towards a better-built environment. We
                        invite you to explore our portfolio and experience firsthand the transformative power of
                        architectural innovation. Whether you are envisioning a cutting-edge healthcare facility or a
                        bespoke residential space, BVA Architects is your trusted partner in turning dreams into
                        reality. Contact us today to embark on a remarkable architectural journey.</p>
                </Col>
            </Row>
        </Container>
    );
};

// Exporting Practice.js to App.js.
export default AboutUs;
