/**
 * Copyright © 2023 - BVA Architects (Pty) Ltd. All rights reserved.
 *
 * @author Chanelle Bösiger <scheeperschanelle@gmail.com>
 */

// Imported React library.
import React from 'react';
// Imported components from React Bootstrap.
import {Container, Row, Col} from "react-bootstrap";
// Imported components.
import SocialLinks from "../components/SocialLinks.js";

/**
 * Function to return the footer component of the site.
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = () => {
    // Dynamically updating the year.
    const year = new Date().getFullYear();

    return (
        <div id="footer">
            <Container className="px-10 py-0 w-100">
                <Row>
                    <Col id="copyright-col" className="col-xl-11 col-lg-10 col-md-10 col-sm-9 col-7 my-auto">
                        <h6 id="copyright-content">&#169; {year} BVA Architects (Pty) Ltd</h6>
                    </Col>
                    <Col id="social-row" className="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-5 my-auto">
                        <SocialLinks/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

// Exporting Footer.js to App.js
export default Footer;