/**
 * Copyright © 2023 - BVA Architects (Pty) Ltd. All rights reserved.
 *
 * @author Chanelle Bösiger <scheeperschanelle@gmail.com>
 */

// Imported React library.
import React, {useState, useRef} from "react";
// Imported components from React Bootstrap.
import {Container, Row, Col, Button, Form} from "react-bootstrap";
// Importing Font Awesome library.
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt, faPhoneAlt, faAt} from "@fortawesome/free-solid-svg-icons";
import {faClock, faEnvelope, faPaperPlane, faCircleCheck, faCircleXmark} from "@fortawesome/free-regular-svg-icons";
// Importing Google Maps library.
import {GoogleMap, MarkerF, useLoadScript} from "@react-google-maps/api";
import {useMemo} from "react";
// Imported components.
import SocialLinks from "../components/SocialLinks.js";
// Imported EmailJS.
// import emailJs from "emailjs-com";
import emailjs from '@emailjs/browser';
// Imported Sweet Alert.
import Swal from "sweetalert2";
// Imported spinner from React Spinners.
import PuffLoader from "react-spinners/PuffLoader";
// Imported Reaptcha.
import Reaptcha from "reaptcha";

/**
 * Function to return the contact page with a form, map component and the contact information of the company.
 * @returns {JSX.Element}
 * @constructor
 */
const Contact = () => {
    // Setting the initial states.
    const [active, setActive] = useState(false);
    const [verified, setVerified] = useState(null);
    const [validated, setValidated] = useState(false);
    const form = useRef();
    const captcha = useRef(null);

    /**
     * Function to verify the reaptcha element.
     */
    const verifyReaptcha = () => {
        captcha.current.getResponse().then(res => {
            setVerified(res)
        })
    }

    /**
     * Function to validate the contact form.
     * @param e
     */
    const validateForm = (e) => {
        const form = e.currentTarget;

        // If the form is not valid, the form will not be submitted.
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }

        setValidated(true);
    }

    /**
     * Function to send the contact form using EmailJS.
     * @param e
     * @returns {Promise<void>}
     */
    const submitContactForm = async (e) => {
        // Stopping the page from refreshing.
        e.preventDefault();

        // Validating the form.
        validateForm(e);
        // Setting the loader to true whilst sending.
        setActive(true);

        let contactFormBtnSpan = document.getElementById("contact_btn_initial_content");
        let contactFormBtnSuccess = document.getElementById("contact_btn_success_content");
        let contactFormBtnError = document.getElementById("contact_btn_error_content");

        // Setting the data for the contact form to be sent via EmailJS.
        emailjs
            .sendForm(
                process.env.REACT_APP_EMAIL_SERVICE_ID,
                process.env.REACT_APP_EMAIL_TEMPLATE_ID,
                form.current,
                process.env.REACT_APP_EMAIL_PUBLIC_ID
            )
            .then(() => {
                    // Showing a success button once the form is sent successfully.
                    contactFormBtnSpan.style.display = 'none';
                    contactFormBtnSuccess.style.display = 'block';
                    contactFormBtnError.style.display = 'none';

                    // Stopping the loader.
                    setActive(false);
                    // Showing a success modal pop-up.
                    Swal.fire({
                        icon: "success",
                        title: "Message Sent Successfully",
                        text: "We will respond to your enquiry as soon as possible",
                        confirmButtonColor: "#808080"
                    }).then(() => {
                        // Reverting form input and button elements.
                        // contactFormBtnSpan.style.display = 'none';
                        // contactFormBtnError.style.display = 'block';
                        // form.current.reset();
                        window.location.reload();
                    })
                }, (error) => {
                    contactFormBtnSpan.style.display = 'none';
                    contactFormBtnSuccess.style.display = 'none';
                    contactFormBtnError.style.display = 'block';

                    // If an error occurred, logging the error.
                    console.log(error.text);
                    // Stopping the loader.
                    setActive(false);
                    // Showing an error modal pop-up.
                    Swal.fire({
                        icon: "error",
                        title: "Oops! Something Went Wrong",
                        text: "Please try again later",
                        confirmButtonColor: "#808080"
                    }).then(() => {
                        // Reverting form button elements.
                        contactFormBtnSpan.style.display = 'block';
                        contactFormBtnError.style.display = 'none';
                    })
                }
            );
    }

    /**
     * Function to authenticate and load the Google Maps element.
     */
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });

    /**
     * Function to set the coordinates of the company on the map.
     * @type {{lng: number, lat: number}}
     */
    const center = useMemo(() => (
        {
            lat: -29.79596,
            lng: 30.84082
        }
    ), []);

    return (
        <Container>
            <header>
                <h3>Contact</h3>
            </header>
            <Row className="contact_content pb-5">
                <Col className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <Row>
                        <Col className="col-xl-4 col-lg-4 col-md-3 col-sm-4 col-4">
                            <h6>
                                <FontAwesomeIcon icon={faPhoneAlt}/> &nbsp;Contact Number:
                            </h6>
                        </Col>
                        <Col className="col-xl-8 col-lg-8 col-md-9 col-sm-8 col-7 pb-5">
                            <h6>+27 31 717 2670</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-xl-4 col-lg-4 col-md-3 col-sm-4 col-4">
                            <h6>
                                <FontAwesomeIcon icon={faEnvelope}/> &nbsp;Email:
                            </h6>
                        </Col>
                        <Col className="col-xl-8 col-lg-8 col-md-9 col-sm-8 col-7 pb-5">
                            <h6> info@bva.co.za</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-xl-4 col-lg-4 col-md-3 col-sm-4 col-4">
                            <h6>
                                <FontAwesomeIcon icon={faMapMarkerAlt}/> &nbsp;Address:
                            </h6>
                        </Col>
                        <Col className="col-xl-8 col-lg-8 col-md-9 col-sm-8 col-7 pb-5">
                            <h6>
                                Block B <br/>
                                Bellevue Campus <br/>
                                5 Bellevue Road <br/>
                                Kloof <br/>
                                3610 <br/>
                                KwaZulu-Natal <br/>
                                South Africa
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-xl-4 col-lg-4 col-md-3 col-sm-4 col-4">
                            <h6>
                                <FontAwesomeIcon icon={faPaperPlane}/> &nbsp;Postal Address:
                            </h6>
                        </Col>
                        <Col className="col-xl-8 col-lg-8 col-md-9 col-sm-8 col-7 pb-5">
                            <h6>
                                PO Box 1787 <br/>
                                Westville <br/>
                                3630 <br/>
                                KwaZulu-Natal <br/>
                                South Africa
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-xl-4 col-lg-4 col-md-3 col-sm-4 col-4">
                            <h6>
                                <FontAwesomeIcon icon={faClock}/> &nbsp;Office Hours:
                            </h6>
                        </Col>
                        <Col className="col-xl-8 col-lg-8 col-md-9 col-sm-8 col-7 pb-5">
                            <h6>
                                Monday to Thursday: 08:00 - 17:00<br/>
                                Friday: 08:00 - 16:00<br/>
                                Weekends and Public Holidays: Closed
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-xl-4 col-lg-4 col-md-3 col-sm-4 col-4">
                            <h6>
                                <FontAwesomeIcon icon={faAt}/> &nbsp;Connect With Us:</h6>
                        </Col>
                        <Col className="contact_social col-xl-8 col-lg-8 col-md-9 col-sm-8 col-7 pb-5">
                            <SocialLinks/>
                        </Col>
                    </Row>
                </Col>
                <Col className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <Form ref={form} onSubmit={submitContactForm} noValidate validated={validated}>
                        <Form.Group className="form-group required">
                            <Form.Label name="sender_name" className="control-label">
                                Name and Surname{" "}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="sender_name"
                                required
                                className="contactinput"
                            />
                            <Form.Control.Feedback type="invalid">This is a Required Field</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-group required">
                            <Form.Label name="sender_email" className="control-label mt-2">
                                Email Address{" "}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="sender_email"
                                required
                                className="contactinput"
                            />
                            <Form.Control.Feedback type="invalid">This is a Required Field</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-group required">
                            <Form.Label name="sender_contact_number" className="control-label mt-2">
                                Contact Number{" "}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="sender_contact_number"
                                required
                                className="contactinput"
                            />
                            <Form.Control.Feedback type="invalid">This is a Required Field</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-group required">
                            <Form.Label name="sender_message" className="control-label mt-2">
                                Message{" "}
                            </Form.Label>
                            <Form.Control as="textarea" name="sender_message" required id="contact-message"/>
                            <Form.Control.Feedback type="invalid">This is a Required Field</Form.Control.Feedback>
                        </Form.Group>
                        <Row className="mt-2">
                            <Col className="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12">
                                <Reaptcha sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                          onVerify={verifyReaptcha}
                                          ref={captcha}
                                />
                            </Col>
                            <Col className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                <Button variant="dark" type="submit" className="btn contact-submit-btn"
                                        disabled={!verified} title="Send Form Message" onClick={submitContactForm}>
                                    <FontAwesomeIcon id="contact_btn_success_content" icon={faCircleCheck}/>
                                    <FontAwesomeIcon id="contact_btn_error_content" icon={faCircleXmark}/>
                                    <span id="contact_btn_initial_content">
                                            {!active ? "Submit" :
                                                <PuffLoader color="#80c040" size={25}/>
                                            }
                                        </span>
                                </Button>
                            </Col>
                        </Row>
                        <Button variant="dark" type="submit" className="btn contact-submit-responsive-btn mt-2"
                                disabled={!verified} title="Send Form Message" onClick={submitContactForm}>
                            Send
                        </Button>
                    </Form>
                    {!isLoaded ? (
                        // Checking whether the map is already loaded. If not, a header is displayed instead.
                        <h3>Loading...</h3>
                    ) : (
                        // Else the map is displayed.
                        <GoogleMap
                            mapContainerClassName="map-container"
                            center={center}
                            zoom={15}
                            mapContainerStyle={{height: "250px"}}
                        >
                            <MarkerF
                                position={{lat: -29.79596, lng: 30.84082}}
                                icon={{url: "/images/contact/BVA-Map-Marker.svg"}}

                            />
                        </GoogleMap>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

// Exporting Contact.js to App.js.
export default Contact;
