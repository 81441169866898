/**
 * Copyright © 2023 - BVA Architects (Pty) Ltd. All rights reserved.
 *
 * @author Chanelle Bösiger <scheeperschanelle@gmail.com>
 */

// Imported React library.
import React from "react";
// Imported components from React Bootstrap.
import {Container, Row, Col, Card, Image} from "react-bootstrap";

/**
 * Function to return the client feedback page showing the current feedback from customers and an image based on the
 * screen size.
 * @returns {JSX.Element}
 * @constructor
 */
function ClientFeedback() {
    // Setting the URLs for the image that will be displayed based on the screen size.
    const referralImageSrc = "./images/practice/referrals/referral_x-large.webp";
    const referralSmallImageSrc = "./images/practice/referrals/referral_small.webp";
    const referralMediumImageSrc = "./images/practice/referrals/referral_medium.webp";
    const referralLargeImageSrc = "./images/practice/referrals/referral_large.webp";

    return (
        <Container className="pb-5 w-100">
            <header>
                <h3>Client Feedback</h3>
            </header>
            <Row>
                <Col className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <Image id="referral_img" fluid src={referralImageSrc} alt="We Are In This Together"/>
                    <Image id="referral_img_small" fluid src={referralSmallImageSrc} alt="We Are In This Together"/>
                    <Image id="referral_img_medium" fluid src={referralMediumImageSrc} alt="We Are In This Together"/>
                    <Image id="referral_img_large" fluid src={referralLargeImageSrc} alt="We Are In This Together"/>
                </Col>
                <Col className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 my-auto">
                    <Card className="mb-2 referral_card">
                        <Card.Body>
                            <Card.Text>
                                &#x201C; The BVA team are deeply knowledgeable about hospital design, facility layouts
                                and
                                patient and public flow to achieve the most cost efficient and effective environment,
                                but with a critical focus on creating a safe and caring environment for patients,
                                doctors, nurses, support staff and the public.
                            </Card.Text>
                            <Card.Text>
                                As a specialist hospital management consultancy specialising in the development of
                                greenfield private hospitals, BVA is our "go to" architect on local projects and we
                                would ideally elect to work with them as first choice. &#x201D;
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="mb-2 referral_card">
                        <Card.Body>
                            <Card.Text>
                                &#x201C; We have worked with many different Professional Teams, in the course of these
                                projects, and BVA Architects remain our Architects of choice.
                            </Card.Text>
                            <Card.Text>
                                They have a wealth of knowledge of the ever-changing Regulations pertaining to Private
                                Health Care. They are extremely cost conscious, delivering a comprehensive service that
                                is compliant, and aesthetically very pleasing.
                            </Card.Text>
                            <Card.Text>
                                I have no hesitation in recommending BVA for any, and all, Hospital Projects, whether
                                it be Greenfield, or Modernisation through Additions and Alterations. &#x201D;
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    <Card className="mb-2 referral_card">
                        <Card.Body>
                            <Card.Text>
                                &#x201C; The service we received from BVA was outstanding, they were always available
                                and
                                responded very quick to any queries we had.
                            </Card.Text>
                            <Card.Text>
                                BVA has experience in plans for Medical facilities and knew exactly what was needed and
                                how it was to be set up. &#x201D;
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    <Card className="mb-2 referral_card">
                        <Card.Body>
                            <Card.Text>
                                &#x201C; The design and construction of the hospital was challenging and on a very
                                restricted
                                site. The BVA team were very committed to the success of the project at every stage and
                                guided the team through the various difficult challenges presented, especially on
                                healthcare architecture. Their knowledge of healthcare and the local DOH standards made
                                a massive positive impact on the success of project. &#x201D;
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    <Card className="mb-2 referral_card">
                        <Card.Body>
                            <Card.Text>
                                &#x201C; BVA Architects have proven to be reliable and efficient. &#x201D;
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

// Exporting Referrals.js to App.js
export default ClientFeedback;