/**
 * Copyright © 2023 - BVA Architects (Pty) Ltd. All rights reserved.
 *
 * @author Chanelle Bösiger <scheeperschanelle@gmail.com>
 */

// Imported React library.
import React, {useEffect, useState} from 'react';
// Imported components from React Bootstrap.
import {Nav, Navbar, Image, Container, NavDropdown} from "react-bootstrap";

// Setting logo URL.
const logo = "./images/assets/BVA-Logo.png";

/**
 * Function to return the navigation component of the site.
 * @returns {JSX.Element}
 * @constructor
 */
const Navigation = () => {
    // Changing the properties of the navigation items based on the current active pathname.
    const location = window.location.pathname;
    const [url, setUrl] = useState(null);

    useEffect(() => {
        setUrl(location);
    }, [location]);

    return (
        <Container className="w-100">
            <Navbar variant="light" expand="lg" collapseOnSelect sticky="top">
                <Container className="p-0">
                    <Navbar.Brand href="/">
                        <Image src={logo} alt="Logo" id="logo" fluid/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link className={(url === "/" ? "active" : "")}
                                      href="/">Home</Nav.Link>
                            <NavDropdown
                                className={(url === "/practice" ? "active" : "")} title="Practice"
                                id="basic-nav-dropdown">
                                <NavDropdown.Item className={(url === "/about" ? "active" : "")}
                                                  href="/about">About Us</NavDropdown.Item>
                                <NavDropdown.Item className={(url === "/community" ? "active" : "")}
                                                  href="/community">Community</NavDropdown.Item>
                                <NavDropdown.Item className={(url === "/client_feedback" ? "active" : "")}
                                                  href="/client_feedback">Client Feedback</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown
                                className={(url === "/portfolio" ? "active" : "")} title="Portfolio"
                                id="basic-nav-dropdown">
                                <NavDropdown.Item className={(url === "/projects" ? "active" : "")}
                                                  href="/projects">Projects</NavDropdown.Item>
                                <NavDropdown.Item className={(url === "/conceptual" ? "active" : "")}
                                                  href="/conceptual">Conceptual</NavDropdown.Item>
                                <NavDropdown.Item className={(url === "/project_list" ? "active" : "")}
                                                  href="/project_list">Full List of Projects</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link className={(url === "/contact" ? "active" : "")}
                                      href="/contact">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Container>
    );
};

// Exporting Navigation.js to App.js
export default Navigation;