/**
 * Copyright © 2023 - BVA Architects (Pty) Ltd. All rights reserved.
 *
 * @author Chanelle Bösiger <scheeperschanelle@gmail.com>
 */

// Imported React library and hooks.
import React from "react";
// Imported components from React Bootstrap.
import {Container} from "react-bootstrap";
// Imported components.
import Hospitals from "../components/Hospitals";
import MedicalCentres from "../components/MedicalCentres";
import PsychiatricHospitals from "../components/PsychiatricHospitals";

/**
 * Function to render all the non-conceptual components and return the project page.
 * @returns {JSX.Element}
 * @constructor
 */
const Projects = () => {

        return (
            <Container className="w-100 pb-5">
                <header>
                    <h3>Projects</h3>
                </header>
                <Hospitals/>
                <MedicalCentres/>
                <PsychiatricHospitals/>
            </Container>
        )
    }
;

// Exporting Projects.js to App.js.
export default Projects;
