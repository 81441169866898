/**
 * Copyright © 2023 - BVA Architects (Pty) Ltd. All rights reserved.
 *
 * @author Chanelle Bösiger <scheeperschanelle@gmail.com>
 */

// Imported React library.
import React from "react";
// Imported stylesheet.
import "./App.css";
// Imported components from React Router Dom.
import {BrowserRouter, Routes, Route} from "react-router-dom";
// Imported components.
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
// Imported screens.
import Landing from "./screens/Landing";
import AboutUs from "./screens/AboutUs";
import Community from "./screens/Community";
import ClientFeedback from "./screens/ClientFeedback";
import Contact from "./screens/Contact";
import Projects from "./screens/Projects";
import Conceptual from "./screens/Conceptual";
import ProjectList from "./screens/ProjectList";

/**
 * Function to return all the screens and components.
 * Using BrowserRouter to set the routes of the application.
 * @returns {JSX.Element}
 * @constructor
 */
function App() {
    return (
        <div className="App">
            <header>
                <Navbar/>
            </header>
            <main>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" exact element={<Landing/>}/>
                        <Route path="/about" exact element={<AboutUs/>}/>
                        <Route path="/community" exact element={<Community/>}/>
                        <Route path="/client_feedback" exact element={<ClientFeedback/>}/>
                        <Route path="/projects" exact element={<Projects/>}/>
                        <Route path="/conceptual" exact element={<Conceptual/>}/>
                        <Route path="/project_list" exact element={<ProjectList/>}/>
                        <Route path="/contact" exact element={<Contact/>}/>
                    </Routes>
                </BrowserRouter>
            </main>
            <footer>
                <Footer/>
            </footer>
        </div>
    );
}

// Exporting App.js to Index.js
export default App;
