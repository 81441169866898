/**
 * Copyright © 2023 - BVA Architects (Pty) Ltd. All rights reserved.
 *
 * @author Chanelle Bösiger <scheeperschanelle@gmail.com>
 */

// Imported React library.
import React, {useState} from 'react';
// Imported components from React Bootstrap.
import {Image, Modal, Carousel} from "react-bootstrap";
// Imported React Photo Album.
import PhotoAlbum from "react-photo-album";
// Imported images.
import josephs from "../assets/images/portfolio/psychiatric_hospitals/Life St Josephs/SJ1.webp";
import catherines
    from "../assets/images/portfolio/psychiatric_hospitals/St Catherine’s Life Path Hospital/SC1.webp";

/**
 * Function to return psychiatric hospitals project component with a photo album and modal.
 * @returns {JSX.Element}
 * @constructor
 */
const PsychiatricHospitals = () => {
    // Setting initial states.
    const [show, setShow] = useState(false);
    const [images, setImages] = useState([]);

    // Show and close functions for the image carousel modal.
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /**
     * Function to map through the images and set the images state, as well as, handle showing the image carousel modal.
     * @param title
     */
    const handleClick = (title) => {
        const images = mapImages(title);

        setImages(images);
        handleShow();
    };

    /**
     * Function to map through and import all the images returned by require.context.
     * @param r
     * @returns {*}
     */
    function importAll(r) {
        return r.keys().map(r);
    }

    // Creating the psychiatric hospital photo array to display via the PhotoAlbum library.
    const photos = [
        {src: catherines, width: 1680, height: 3648, title: "St Catherine’s Life Path Hospital"},
        {src: josephs, width: 448, height: 298, title: "Life St Josephs"},
    ];

    /**
     * Function to import all the images, based on the project title, returned by require.context via directory and
     * type to be displayed on the page.
     * @param projectTitle
     * @returns {*}
     */
    const mapImages = (projectTitle) => {
        let project;

        if (projectTitle === "St Catherine’s Life Path Hospital") {
            project = importAll(
                require.context("../assets/images/portfolio/psychiatric_hospitals/St Catherine’s Life Path Hospital/",
                    true,
                    /\.(png|jpe?g|JPG|svg|webp)$/)
            )
        } else if (projectTitle === "Life St Josephs") {
            project = importAll(
                require.context("../assets/images/portfolio/psychiatric_hospitals/Life St Josephs/",
                    true,
                    /\.(png|jpe?g|JPG|svg|webp)$/
                )
            )
        }

        // Checking whether the project array is empty.
        if (project.length !== 0) {
            // If not, iterating over the project array and removing the /static/media/ string before the file name.
            return project.map(function (imagePath, i) {
                const fileName = imagePath.substring(0, imagePath.indexOf('.'));
                const imageText = fileName.replace("/static/media/", "");

                // Returning the image path, index and the project name.
                return {path: imagePath, i: i, text: imageText};
            });
        }
    }

    return (
        <div>
            <header>
                <h4>Psychiatric Hospitals</h4>
            </header>

            {/* Large Screen Photo Album. */}
            <div className="portfolio_gallery_large pb-2">
                <PhotoAlbum layout="columns"
                            columns={3}
                            photos={photos}
                            renderPhoto={({photo, wrapperStyle, renderDefaultPhoto}) => (
                                <div
                                    onClick={() => {
                                        handleClick(photo.title)
                                    }}
                                    className="property-container" style={{...wrapperStyle}}>
                                    {renderDefaultPhoto({wrapped: true})}
                                    {photo.title && (
                                        <div className="property_img_overlay">
                                            <h6>{photo.title}</h6>
                                        </div>
                                    )}
                                </div>
                            )}
                />
            </div>

            {/* Medium Screen Photo Album. */}
            <div className="portfolio_gallery_medium pb-2">
                <PhotoAlbum layout="columns"
                            columns={2}
                            photos={photos}
                            renderPhoto={({photo, wrapperStyle, renderDefaultPhoto}) => (
                                <div
                                    onClick={() => {
                                        handleClick(photo.title)
                                    }}
                                    className="property-container" style={{...wrapperStyle}}>
                                    {renderDefaultPhoto({wrapped: true})}
                                    {photo.title && (
                                        <div className="property_img_overlay">
                                            <h6>{photo.title}</h6>
                                        </div>
                                    )}
                                </div>
                            )}
                />
            </div>

            {/* Small Screen Photo Album. */}
            <div className="portfolio_gallery_small pb-2">
                <PhotoAlbum layout="columns"
                            columns={1}
                            photos={photos}
                            renderPhoto={({photo, wrapperStyle, renderDefaultPhoto}) => (
                                <div
                                    onClick={() => {
                                        handleClick(photo.title)
                                    }}
                                    className="property-container" style={{...wrapperStyle}}>
                                    {renderDefaultPhoto({wrapped: true})}
                                    {photo.title && (
                                        <div className="property_img_overlay">
                                            <h6>{photo.title}</h6>
                                        </div>
                                    )}
                                </div>
                            )}
                />
            </div>

            <Modal
                size="xl"
                centered
                show={show}
                onHide={handleClose}>
                <Modal.Body>
                    <Carousel className="p-0" slide={false} pause="false" variant="dark" controls={true}
                              indicators={false}>
                        {
                            images.length !== 0 ? (
                                images.map(function (filePath) {
                                    return (
                                        <Carousel.Item key={filePath.i} interval={2500}>
                                            <Image
                                                fluid
                                                className="d-block m-auto w-100 portfolio_carousel_img"
                                                src={filePath.path}
                                                alt="Carousel"
                                            />
                                        </Carousel.Item>
                                    )
                                })
                            ) : (
                                <div></div>
                            )
                        }
                    </Carousel>
                </Modal.Body>
            </Modal>
        </div>
    );
};

// Exporting PsychiatricHospitals.js to App.js.
export default PsychiatricHospitals;