/**
 * Copyright © 2023 - BVA Architects (Pty) Ltd. All rights reserved.
 *
 * @author Chanelle Bösiger <scheeperschanelle@gmail.com>
 */

// Imported React library.
import React from "react";
// Imported components from React Bootstrap.
import {Container, Row} from "react-bootstrap";
import PhotoAlbum from "react-photo-album";
import community1 from "../assets/images/practice/community/1.webp";
import community2 from "../assets/images/practice/community/2.webp";
import community3 from "../assets/images/practice/community/3.webp";
import community4 from "../assets/images/practice/community/4.webp";
import community5 from "../assets/images/practice/community/5.webp";
import community6 from "../assets/images/practice/community/6.webp";
import community7 from "../assets/images/practice/community/7.webp";
import community8 from "../assets/images/practice/community/8.webp";

/**
 * Function to return the community page with text and photo elements.
 * @returns {JSX.Element}
 * @constructor
 */
function Community() {
    // Creating the community photo array to display via the PhotoAlbum library.
    const photos = [
        {src: community3, width: 990, height: 660},
        {src: community5, width: 990, height: 660},
        {src: community4, width: 990, height: 660},
        {src: community8, width: 660, height: 660},
        {src: community7, width: 660, height: 660},
        {src: community1, width: 660, height: 660},
        {src: community2, width: 825, height: 660},
        {src: community6, width: 880, height: 660},
    ];

    return (
        <Container className="pb-5 w-100">
            <header>
                <h3>Community</h3>
            </header>
            <Row>
                <p>We firmly believe in the power of community work to bring about positive social upliftment. As
                    part of our commitment to making a meaningful difference in society, we have actively engaged
                    in initiatives that support the less fortunate in KwaZulu-Natal.
                </p>
                <p>
                    We undertake to assist Zero2Five, based in our offices precinct, with their projects whenever
                    possible.
                </p>
            </Row>
            <Row>
                <div className="portfolio_gallery_large">
                    <PhotoAlbum layout="columns" columns={3} photos={photos}/>
                </div>
                <div className="portfolio_gallery_medium">
                    <PhotoAlbum layout="columns" columns={2} photos={photos}/>
                </div>
                <div className="portfolio_gallery_small">
                    <PhotoAlbum layout="columns" columns={1} photos={photos}/>
                </div>
            </Row>
        </Container>
    );
}

// Exporting Community.js to App.js
export default Community;
