/**
 * Copyright © 2023 - BVA Architects (Pty) Ltd. All rights reserved.
 *
 * @author Chanelle Bösiger <scheeperschanelle@gmail.com>
 */

// Imported React library.
import React from 'react';
// Imported components from React Bootstrap.
import {Container, Col, Row} from "react-bootstrap";
// Imported project list JSON file.
import projectListData from "../assets/json/project_list.json";

/**
 * Function to return the project list page with the list of projects.
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectList = () => {
    // Getting the data values from the project_list.json file.
    const sortedProjectListArray = Object.values(projectListData);

    // Looping through the array and getting the projects from the data.
    for (let i = 0; i < sortedProjectListArray.length; i++) {
        const projectsList = sortedProjectListArray[i].projects;

        // Sorting the array of projects in alphabetical order.
        projectsList.sort();

        // Checking whether there are more than two projects per category and splitting the projects into three
        // columns.
        if (projectsList.length >= 2) {
            const newArray = splitArrayToColumns(projectsList, 3);

            // Pushing the new data into the array.
            projectsList.push(newArray);
        }
    }

    /**
     * Function to split array into columns.
     * @param array
     * @param columns
     * @returns {*[]}
     */
    function splitArrayToColumns(array, columns) {
        // Creating a new array.
        let result = [];

        // Looping through the columns and splitting the data into columns.
        for (let i = columns; i > 0; i--) {
            result.push(array.splice(0, Math.ceil(array.length / i)));
        }

        return result;
    }

    return (
        <Container className="w-100 pb-5">
            <header>
                <h3>Project List</h3>
            </header>

            {
                // Iterating over the projects array and returning the relevant header of the project categories to be
                // displayed on the page.
                Object.keys(sortedProjectListArray).map((key, i) => {
                    return (
                        <div key={i}>
                            <h4>{sortedProjectListArray[key].header}</h4>
                            <ul className="project_unordered_list">
                                {/* Looping through the projects to be displayed on the page. */}
                                {sortedProjectListArray[key].projects.map((project, i) => (
                                    // Checking whether the project is an object.
                                    typeof project !== "object" ?
                                        (
                                            // If not, a list item is returned with the project.
                                            <li key={i}>{project}</li>
                                        ) : (
                                            // Else, a row is returned with the projects split into three columns.
                                            <Row>
                                                {Object.keys(project).map((key, i) => {
                                                    return (
                                                        <Col key={i}
                                                             className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                                            {Object.values(project[key]).map((value, i) => {
                                                                return (
                                                                    <li key={i}>{value}</li>
                                                                )
                                                            })}
                                                        </Col>
                                                    )
                                                })
                                                }
                                            </Row>
                                        )
                                ))}
                            </ul>
                        </div>
                    );
                })
            }
        </Container>
    );
};

// Exporting ProjectList.js to App.js.
export default ProjectList;