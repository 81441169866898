/**
 * Copyright © 2023 - BVA Architects (Pty) Ltd. All rights reserved.
 *
 * @author Chanelle Bösiger <scheeperschanelle@gmail.com>
 */

// Imported React library and hooks.
import React from "react";
// Imported components from React Bootstrap.
import {Carousel, Col, Image, Row} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import $ from "jquery";

/**
 * Function to return the landing page with an image carousel and image list of project specialities.
 * @returns {JSX.Element}
 * @constructor
 */
const Landing = () => {
    /**
     * Function to map through and import all the images returned by require.context.
     * @param r
     * @returns {*}
     */
    function importAll(r) {
        return r.keys().map(r);
    }

    // Importing all the images returned by require.context via directory and type to be displayed on the page.
    const carouselImagesLarge =
        importAll(require.context("../assets/images/landing/carousel/large/", true, /\.(png|jpe?g|svg|webp)$/));
    const carouselImagesResponsive =
        importAll(require.context("../assets/images/landing/carousel/responsive/", true, /\.(png|jpe?g|svg|webp)$/));
    const specialityImages =
        importAll(require.context("../assets/images/landing/specialities/", true, /\.(png|jpe?g|svg|webp)$/));

    /**
     * Function to set the column classes of the speciality images , based on the current screen size.
     */
    const manageSpecialityXlResponsiveCols = () => {
        // Getting the current screen width.
        const screenWidth = window.screen.width;
        // Getting the speciality image elements.
        const specialityImageCol = document.querySelectorAll(".speciality_images");

        // Looping through the speciality images and setting the column classes.
        specialityImageCol.forEach((col) => {
            // Checking whether the screen size is larger than 1600 to display images in one row.
            if (screenWidth > 1600) {
                col.classList.add("col-xl")
                col.classList.remove("col-xl-3")
                col.classList.remove("col-xl-4")
            }

            // Checking whether the screen size is less than 1600 to display images in three columns.
            if (screenWidth < 1600) {
                col.classList.remove("col-xl")
                col.classList.remove("col-xl-3")
                col.classList.add("col-xl-4")
            }

            // Checking whether the screen size is larger than 1400 and less than 1500 to display images in four
            // columns.
            if (screenWidth > 1400 && screenWidth < 1500) {
                col.classList.remove("col-xl")
                col.classList.remove("col-xl-4")
                col.classList.add("col-xl-3")
            }
        })
    }

    /**
     * Setting the column classes when the page is loaded, based on the current screen size.
     */
    $(document).ready(function () {
        manageSpecialityXlResponsiveCols();
    })

    /**
     * Setting the column classes when the screen size changes.
     */
    window.onresize = function () {
        manageSpecialityXlResponsiveCols();
    }

    return (
        <div id="landing">
            <Row className="w-100 pt-0 px-xl-5 px-lg-4 px-md-3 px-sm-2 px-2 m-0">
                {/* Extra Large Carousel */}
                <Carousel className="p-0 landing_carousel_large" fade pause="false" variant="dark" controls={false}
                          indicators={false}>
                    {
                        // Checking whether there are landing carousel images.
                        carouselImagesLarge.length !== 0 ? (
                            // If there are, looping through array and returning each image.
                            carouselImagesLarge.map(function (fileName, i) {
                                return <Carousel.Item key={i} interval={2500}>
                                    <Image
                                        fluid
                                        className="d-block w-100"
                                        src={fileName}
                                        alt="Carousel"
                                    />
                                </Carousel.Item>
                            })
                        ) : (
                            // Else, returning empty div.
                            <div></div>
                        )
                    }
                </Carousel>

                {/* Responsive Carousel */}
                <Carousel className="p-0 landing_carousel_responsive" fade pause="false" variant="dark" controls={false}
                          indicators={false}>
                    {
                        // Checking whether there are landing carousel images.
                        carouselImagesResponsive.length !== 0 ? (
                            // If there are, looping through array and returning each image.
                            carouselImagesResponsive.map(function (fileName, i) {
                                return <Carousel.Item key={i} interval={2500}>
                                    <Image
                                        fluid
                                        className="d-block w-100"
                                        src={fileName}
                                        alt="Carousel"
                                    />
                                </Carousel.Item>
                            })
                        ) : (
                            // Else, returning empty div.
                            <div></div>
                        )
                    }
                </Carousel>
            </Row>

            {/* Speciality Images */}
            <Row className="w-100 pt-1 px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1 m-0 pb-5">
                {
                    // Checking whether there are speciality images.
                    specialityImages.length !== 0 ? (
                        // If there are, looping through array and returning each image.
                        specialityImages.map(function (imagePath, i) {
                            const fileName = imagePath.substring(0, imagePath.indexOf('.'));
                            const imageText = fileName.replace("/static/media/", "");

                            return (
                                <Col
                                    key={i}
                                    className="col-xl col-lg-6 col-md-6 col-sm-6 col-6 p-1 properties speciality_images"
                                >
                                    <div className="property-container">
                                        <Image fluid src={imagePath}
                                               alt="Healthcare Facilities"/>
                                        <div className="property_img_overlay">
                                            <h6>{imageText}</h6>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    ) : (
                        // Else, returning empty div.
                        <div></div>
                    )
                }
            </Row>
        </div>
    );
};

// Exporting Landing.js to App.js.
export default Landing;
