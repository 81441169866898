/**
 * Copyright © 2023 - BVA Architects (Pty) Ltd. All rights reserved.
 *
 * @author Chanelle Bösiger <scheeperschanelle@gmail.com>
 */

// Imported React library.
import React, {useState} from 'react';
// Imported components from React Bootstrap.
import {Image, Modal, Carousel, Container} from "react-bootstrap";
// Imported React Photo Album.
import PhotoAlbum from "react-photo-album";
// Imported images.
import hillcrestDay from "../assets/images/portfolio/conceptual/Busamed Hillcrest Day Hospital/Image2.webp";
import hillcrestExpansion from "../assets/images/portfolio/conceptual/Busamed Hillcrest Expansion/BHPH image 2.webp";
import cornubia from "../assets/images/portfolio/conceptual/Cornubia Day Hospital/Image7.webp";
import herolim from "../assets/images/portfolio/conceptual/Herolim Private Hospital/Herolim Private Hospital 1.webp";
import hluhluwe from "../assets/images/portfolio/conceptual/Hluhluwe Hospital/Hluhluwe 10.webp";
import olive from "../assets/images/portfolio/conceptual/Olive Tree Church/Image11.webp";
import palm from "../assets/images/portfolio/conceptual/Palm Lakes Private Hospital/Palm Lakes 13.webp";
import nest
    from "../assets/images/portfolio/conceptual/The Nest Hotel/The Nest 1.webp";
import west from "../assets/images/portfolio/conceptual/The West Hospital/Image3.webp";
import victoria from "../assets/images/portfolio/conceptual/Victoria Mother and Child Hospital/Image 04_000.webp";
import westville from "../assets/images/portfolio/conceptual/Life Westville Hospital/Image3.webp";
import ocean from "../assets/images/portfolio/conceptual/Ocean and Land Logistics/Ocean Logistics 1.webp";

/**
 * Function to return conceptual project page with a photo album and modal.
 * @returns {JSX.Element}
 * @constructor
 */
const Conceptual = () => {
    // Setting initial states.
    const [show, setShow] = useState(false);
    const [images, setImages] = useState([]);

    // Show and close functions for the image carousel modal.
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /**
     * Function to map through the images and set the images state, as well as, handle showing the image carousel modal.
     * @param title
     * @param fileType
     */
    const handleClick = (title, fileType) => {
        const images = mapImages(title, fileType);

        setImages(images);

        // Removing the carousel modal controls if the file type is video. Doing so after timeout to ensure that the
        // carousel is displayed after image click and modal show.
        setTimeout(() => {
            if (fileType === "video") {
                document.getElementsByClassName("carousel-control-prev")[0].style.display = "none";
                document.getElementsByClassName("carousel-control-next")[0].style.display = "none";
            }
        }, 100);

        handleShow();
    };

    /**
     * Function to map through and import all the images returned by require.context.
     * @param r
     * @returns {*}
     */
    function importAll(r) {
        return r.keys().map(r);
    }

    // Creating the conceptual photo array to display via the PhotoAlbum library.
    const photos = [
        {src: hillcrestDay, width: 5152, height: 3864, title: "Busamed Hillcrest Day Hospital", fileType: "image"},
        {src: hillcrestExpansion, width: 1000, height: 667, title: "Busamed Hillcrest Expansion", fileType: "image"},
        {src: cornubia, width: 1000, height: 667, title: "Cornubia Day Hospital", fileType: "image"},
        {src: herolim, width: 1000, height: 667, title: "Herolim Private Hospital", fileType: "image"},
        {src: hluhluwe, width: 1000, height: 667, title: "Hluhluwe Hospital", fileType: "image"},
        {src: olive, width: 1000, height: 667, title: "Olive Tree Church", fileType: "image"},
        {src: palm, width: 1000, height: 667, title: "Palm Lakes Private Hospital", fileType: "image"},
        {src: nest, width: 1000, height: 667, title: "The Nest Hotel", fileType: "image"},
        {src: west, width: 1000, height: 667, title: "The West Hospital", fileType: "image"},
        {src: victoria, width: 1000, height: 667, title: "Victoria Mother and Child Hospital", fileType: "image"},
        {src: westville, width: 1000, height: 667, title: "Life Westville Hospital", fileType: "image"},
        {src: ocean, width: 1920, height: 1080, title: "Ocean and Land Logistics", fileType: "image"},
    ];

    // Creating the conceptual video array to display via the PhotoAlbum library.
    const videos = [
        {src: hillcrestDay, width: 5152, height: 3864, title: "Busamed Hillcrest Day Hospital", fileType: "video"},
        {src: ocean, width: 1920, height: 1080, title: "Ocean and Land Logistics", fileType: "video"},
    ];

    /**
     * Function to import all the images, based on the project title and file type, returned by require.context via
     * directory and type to be displayed on the page.
     * @param projectTitle
     * @param fileType
     * @returns {*}
     */
    const mapImages = (projectTitle, fileType) => {
        let project;

        // Checking whether the file type is image or video, to return the relevant media.
        if (fileType === "image") {
            if (projectTitle === "Busamed Hillcrest Day Hospital") {
                project = importAll(
                    require.context("../assets/images/portfolio/conceptual/Busamed Hillcrest Day Hospital/",
                        true,
                        /\.(png|jpe?g|JPG|svg|webp)$/)
                )
            } else if (projectTitle === "Busamed Hillcrest Expansion") {
                project = importAll(
                    require.context("../assets/images/portfolio/conceptual/Busamed Hillcrest Expansion/",
                        true,
                        /\.(png|jpe?g|JPG|svg|webp)$/
                    )
                )
            } else if (projectTitle === "Cornubia Day Hospital") {
                project = importAll(
                    require.context("../assets/images/portfolio/conceptual/Cornubia Day Hospital/",
                        true,
                        /\.(png|jpe?g|JPG|svg|webp)$/
                    )
                )
            } else if (projectTitle === "Herolim Private Hospital") {
                project = importAll(
                    require.context("../assets/images/portfolio/conceptual/Herolim Private Hospital/",
                        true,
                        /\.(png|jpe?g|JPG|svg|webp)$/
                    )
                )
            } else if (projectTitle === "Hluhluwe Hospital") {
                project = importAll(
                    require.context("../assets/images/portfolio/conceptual/Hluhluwe Hospital/",
                        true,
                        /\.(png|jpe?g|JPG|svg|webp)$/
                    )
                )
            } else if (projectTitle === "Olive Tree Church") {
                project = importAll(
                    require.context("../assets/images/portfolio/conceptual/Olive Tree Church/",
                        true,
                        /\.(png|jpe?g|JPG|svg|webp)$/
                    )
                )
            } else if (projectTitle === "Palm Lakes Private Hospital") {
                project = importAll(
                    require.context("../assets/images/portfolio/conceptual/Palm Lakes Private Hospital/",
                        true,
                        /\.(png|jpe?g|JPG|svg|webp)$/
                    )
                )
            } else if (projectTitle === "The Nest Hotel") {
                project = importAll(
                    require.context("../assets/images/portfolio/conceptual/The Nest Hotel/",
                        true,
                        /\.(png|jpe?g|JPG|svg|webp)$/
                    )
                )
            } else if (projectTitle === "The West Hospital") {
                project = importAll(
                    require.context("../assets/images/portfolio/conceptual/The West Hospital/",
                        true,
                        /\.(png|jpe?g|JPG|svg|webp)$/
                    )
                )
            } else if (projectTitle === "Victoria Mother and Child Hospital") {
                project = importAll(
                    require.context("../assets/images/portfolio/conceptual/Victoria Mother and Child Hospital/",
                        true,
                        /\.(png|jpe?g|JPG|svg|webp)$/
                    )
                )
            } else if (projectTitle === "Life Westville Hospital") {
                project = importAll(
                    require.context("../assets/images/portfolio/conceptual/Life Westville Hospital/",
                        true,
                        /\.(png|jpe?g|JPG|svg|webp)$/
                    )
                )
            } else if (projectTitle === "Ocean and Land Logistics") {
                project = importAll(
                    require.context("../assets/images/portfolio/conceptual/Ocean and Land Logistics/",
                        true,
                        /\.(png|jpe?g|JPG|svg|webp)$/
                    )
                )
            }
        } else {
            if (projectTitle === "Busamed Hillcrest Day Hospital") {
                project = importAll(
                    require.context("../assets/images/portfolio/conceptual/Busamed Hillcrest Day Hospital/videos/",
                        true,
                        /\.(mp4)$/
                    )
                )
            } else if (projectTitle === "Ocean and Land Logistics") {
                project = importAll(
                    require.context("../assets/images/portfolio/conceptual/Ocean and Land Logistics/videos/",
                        true,
                        /\.(mp4)$/
                    )
                )
            }
        }

        // Checking whether the project array is empty.
        if (project.length !== 0) {
            // If not, iterating over the project array and removing the /static/media/ string before the file name.
            return project.map(function (imagePath, i) {
                const fileName = imagePath.substring(0, imagePath.indexOf('.'));
                const imageText = fileName.replace("/static/media/", "");

                // Returning the image path, index and the project name.
                return {path: imagePath, i: i, text: imageText};
            });
        }
    }

    return (
        <Container className="w-100 pb-5">
            <header>
                <h3>Conceptual</h3>
            </header>
            <header>
                <h4>Images</h4>
            </header>

            {/* Large Screen Photo Album. */}
            <div className="portfolio_gallery_large pb-2">
                <PhotoAlbum layout="columns"
                            columns={3}
                            photos={photos}
                            renderPhoto={({photo, wrapperStyle, renderDefaultPhoto}) => (
                                <div
                                    onClick={() => {
                                        handleClick(photo.title, "image")
                                    }}
                                    className="property-container" style={{...wrapperStyle}}>
                                    {renderDefaultPhoto({wrapped: true})}
                                    {photo.title && (
                                        <div className="property_img_overlay">
                                            <h6>{photo.title}</h6>
                                        </div>
                                    )}
                                </div>
                            )}
                />
            </div>

            {/* Medium Screen Photo Album. */}
            <div className="portfolio_gallery_medium pb-2">
                <PhotoAlbum layout="columns"
                            columns={2}
                            photos={photos}
                            renderPhoto={({photo, wrapperStyle, renderDefaultPhoto}) => (
                                <div
                                    onClick={() => {
                                        handleClick(photo.title)
                                    }}
                                    className="property-container" style={{...wrapperStyle}}>
                                    {renderDefaultPhoto({wrapped: true})}
                                    {photo.title && (
                                        <div className="property_img_overlay">
                                            <h6>{photo.title}</h6>
                                        </div>
                                    )}
                                </div>
                            )}
                />
            </div>

            {/* Small Screen Photo Album. */}
            <div className="portfolio_gallery_small pb-2">
                <PhotoAlbum layout="columns"
                            columns={1}
                            photos={photos}
                            renderPhoto={({photo, wrapperStyle, renderDefaultPhoto}) => (
                                <div
                                    onClick={() => {
                                        handleClick(photo.title)
                                    }}
                                    className="property-container" style={{...wrapperStyle}}>
                                    {renderDefaultPhoto({wrapped: true})}
                                    {photo.title && (
                                        <div className="property_img_overlay">
                                            <h6>{photo.title}</h6>
                                        </div>
                                    )}
                                </div>
                            )}
                />
            </div>

            <header>
                <h4>Videos</h4>
            </header>

            {/* Large Screen Video Album. */}
            <div className="portfolio_gallery_large">
                <PhotoAlbum layout="columns"
                            columns={3}
                            photos={videos}
                            renderPhoto={({photo, wrapperStyle, renderDefaultPhoto}) => (
                                <div
                                    onClick={() => {
                                        handleClick(photo.title, "video")
                                    }}
                                    className="property-container" style={{...wrapperStyle}}>
                                    {renderDefaultPhoto({wrapped: true})}
                                    {photo.title && (
                                        <div className="property_img_overlay">
                                            <h6>{photo.title}</h6>
                                        </div>
                                    )}
                                </div>
                            )}
                />
            </div>

            {/* Medium Screen Video Album. */}
            <div className="portfolio_gallery_medium">
                <PhotoAlbum layout="columns"
                            columns={2}
                            photos={videos}
                            renderPhoto={({photo, wrapperStyle, renderDefaultPhoto}) => (
                                <div
                                    onClick={() => {
                                        handleClick(photo.title, "video")
                                    }}
                                    className="property-container" style={{...wrapperStyle}}>
                                    {renderDefaultPhoto({wrapped: true})}
                                    {photo.title && (
                                        <div className="property_img_overlay">
                                            <h6>{photo.title}</h6>
                                        </div>
                                    )}
                                </div>
                            )}
                />
            </div>

            {/* Small Screen Video Album. */}
            <div className="portfolio_gallery_small">
                <PhotoAlbum layout="columns"
                            columns={1}
                            photos={videos}
                            renderPhoto={({photo, wrapperStyle, renderDefaultPhoto}) => (
                                <div
                                    onClick={() => {
                                        handleClick(photo.title, "video")
                                    }}
                                    className="property-container" style={{...wrapperStyle}}>
                                    {renderDefaultPhoto({wrapped: true})}
                                    {photo.title && (
                                        <div className="property_img_overlay">
                                            <h6>{photo.title}</h6>
                                        </div>
                                    )}
                                </div>
                            )}
                />
            </div>

            <Modal
                size="xl"
                centered
                show={show}
                onHide={handleClose}>
                <Modal.Body>
                    <Carousel className="p-0" id="portfolio_carousel" slide={false} pause="false" variant="dark"
                              controls={true} indicators={false}>
                        {
                            images.length !== 0 ? (
                                images.map(function (filePath) {
                                    if (!filePath.path.includes(".mp4")) {
                                        return (
                                            <Carousel.Item key={filePath.i} interval={2500}>
                                                <Image
                                                    fluid
                                                    className="d-block w-100 m-auto portfolio_carousel_img"
                                                    src={filePath.path}
                                                    alt="Carousel"
                                                />
                                            </Carousel.Item>
                                        )
                                    } else {
                                        return (
                                            <Carousel.Item key={filePath.i} interval={2500}>
                                                <video className="portfolio_carousel_video" controlsList="nodownload"
                                                       controls>
                                                    <source src={filePath.path} type="video/mp4"/>
                                                    Your browser does not support the video tag.
                                                </video>
                                            </Carousel.Item>
                                        )
                                    }
                                })
                            ) : (
                                <div></div>
                            )
                        }
                    </Carousel>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

// Exporting Conceptual.js to App.js.
export default Conceptual;