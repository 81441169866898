/**
 * Copyright © 2023 - BVA Architects (Pty) Ltd. All rights reserved.
 *
 * @author Chanelle Bösiger <scheeperschanelle@gmail.com>
 */

// Imported React library.
import React from 'react';
// Importing Font Awesome library.
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";

/**
 * Function to return the Instagram and Facebook clickable icons for the contact page and footer component.
 * @returns {JSX.Element}
 * @constructor
 */
const SocialLinks = () => {
    return (
        <div>
            <a
                href="https://www.instagram.com/bvaarchitects/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
                title="Instagram"
            >
                <FontAwesomeIcon
                    icon={faInstagram}
                    className="social-icons"
                />
            </a>
            <a
                href="https://www.facebook.com/bvaarchitecture/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
                title="Facebook"
            >
                <FontAwesomeIcon icon={faFacebook} className="social-icons"/>
            </a>
        </div>
    );
};

// Exporting SocialLinks.js to Contact.js and Footer.js.
export default SocialLinks;